import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getMessaging, getToken } from 'firebase/messaging';

const firebaseConfig = {
	apiKey: 'AIzaSyAOBCfqvbY5ql9f_oTW_owBRfLFT3QWnok',
	authDomain: 'entero.firebaseapp.com',
	projectId: 'entero',
	storageBucket: 'entero.appspot.com',
	messagingSenderId: '136084540854',
	appId: '1:136084540854:web:9556527077db10bf0c4517',
	measurementId: 'G-LKNTVGPCJS',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const messaging = getMessaging(app);
export { analytics, messaging };
export const fetchToken = () => {
	return getToken(messaging)
		.then((currentToken) => {
			if (currentToken) {
				// console.log('current token for client: ', currentToken);
				return currentToken;
			} else {
				console.log('No registration token available. Request permission to generate one.');
				return null;
			}
		})
		.catch((err) => {
			console.log('An error occurred while retrieving token. ', err);
			return null;
		});
};
