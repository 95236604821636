import { RouteObject } from 'react-router-dom';
import { RouteMeta } from '@/pages/routes';

const route: RouteObject = {
	path: 'medications',
	loader: () => ({
		meta: {
			title: 'Medications',
		} as RouteMeta,
	}),
	lazy: async () => ({ Component: (await import('@/modules/medications/Layout')).default }),
	children: [
		{
			path: ':script',
			index: true,
			id: 'scripts',
			lazy: async () => ({ Component: (await import('@/modules/medications/pages/Scripts')).default }),
		},
	],
};
export default route;
