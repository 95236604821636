import { RouteObject } from 'react-router-dom';
import { RouteMeta } from '@/pages/routes';

const route: RouteObject = {
	path: 'hepcpipeline',
	loader: () => ({
		meta: {
			title: 'Hep C Pipeline',
			disabled: true,
		} as RouteMeta,
	}),
	lazy: async () => ({ Component: (await import('@/modules/hep-c-pipeline/Layout')).default }),
	children: [
		{
			path: ':pipeline',
			index: true,
			id: 'hepcpipeline',
			lazy: async () => ({ Component: (await import('@/modules/hep-c-pipeline/pages/List')).default }),
		},
	],
};
export default route;
