import { RouteObject } from 'react-router-dom';
import { RouteMeta } from '@/pages/routes';

const route: RouteObject = {
	path: 'wvc',
	lazy: async () => ({ Component: (await import('@/modules/wvc/Layout')).default }),
	children: [
		{
			path: 'appointments',
			index: true,
			id: 'wvcAppointments',
			loader: () => ({
				meta: {
					title: 'Appointment',
					disabled: true,
					htmlTitlePattern: 'Appointment',
				} as RouteMeta,
			}),
			lazy: async () => ({ Component: (await import('@/modules/wvc/pages/Appointments')).default }),
		},
		{
			path: 'blogs',
			index: true,
			id: 'wvcBlogs',
			loader: () => ({
				meta: {
					title: 'Blogs',
					disabled: true,
					htmlTitlePattern: 'Blogs',
				} as RouteMeta,
			}),
			lazy: async () => ({ Component: (await import('@/modules/wvc/pages/Blogs')).default }),
		},

		{
			id: 'wvcSettings',
			index: true,
			path: 'settings',
			lazy: async () => ({ Component: (await import('@/modules/wvc/pages/Settings')).default }),
			loader: () => ({
				meta: {
					title: 'WVC SMTP Settings',
					disabled: true,
				} as RouteMeta,
			}),
		},
		{
			id: 'wvcEmailTemplates',
			index: true,
			path: 'emailTemplates',
			lazy: async () => ({ Component: (await import('@/modules/wvc/pages/EmailTemplates')).default }),
			loader: () => ({
				meta: {
					title: 'WVC Email Templates',
					disabled: true,
				} as RouteMeta,
			}),
		},
		{
			id: 'wvcSmsTemplates',
			index: true,
			path: 'smsTemplates',
			lazy: async () => ({ Component: (await import('@/modules/wvc/pages/SmsTemplates')).default }),
			loader: () => ({
				meta: {
					title: 'WVC SMS Templates',
					disabled: true,
				} as RouteMeta,
			}),
		},
		{
			id: 'wvcGallery',
			index: true,
			path: 'gallery',
			lazy: async () => ({ Component: (await import('@/modules/wvc/pages/Gallery')).default }),
			loader: () => ({
				meta: {
					title: 'WVC Gallery',
					disabled: true,
				} as RouteMeta,
			}),
		},
		{
			id: 'pbxReport',
			index: true,
			path: 'pnx',
			lazy: async () => ({ Component: (await import('@/modules/wvc/pages/PbxReport')).default }),
			loader: () => ({
				meta: {
					title: 'PBX Report',
					disabled: true,
				} as RouteMeta,
			}),
		},
	],
};
export default route;
