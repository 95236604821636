import { RouteObject } from 'react-router-dom';
import { RouteMeta } from '@/pages/routes';

const route: RouteObject = {
	path: 'settings',
	loader: () => ({
		meta: {
			title: 'Settings',
			htmlTitlePattern: '#title :: Settings ',
		} as RouteMeta,
	}),
	lazy: async () => ({ Component: (await import('@/modules/settings/Layout')).default }),
	children: [
		{
			id: 'pharmaciesSettings',
			index: true,
			path: 'pharmacies',
			lazy: async () => ({ Component: (await import('@/modules/settings/pages/Pharmacies')).default }),
			loader: () => ({
				meta: {
					title: 'Pharmacies',
					disabled: true,
				} as RouteMeta,
			}),
		},
		{
			id: 'profilesSettings',
			index: true,
			path: 'profiles',
			lazy: async () => ({ Component: (await import('@/modules/settings/pages/Profiles')).default }),
			loader: () => ({
				meta: {
					title: 'Profiles',
					disabled: true,
				} as RouteMeta,
			}),
		},
		{
			id: 'medicationsSettings',
			index: true,
			path: 'medications',
			lazy: async () => ({ Component: (await import('@/modules/settings/pages/Medications')).default }),
			loader: () => ({
				meta: {
					title: 'Medications',
					disabled: true,
				} as RouteMeta,
			}),
		},
		{
			id: 'accountsSettings',
			index: true,
			path: 'accounts',
			lazy: async () => ({ Component: (await import('@/modules/settings/pages/Accounts')).default }),
			loader: () => ({
				meta: {
					title: 'Accounts',
					disabled: true,
				} as RouteMeta,
			}),
		},
		{
			id: 'insuranceTypesSettings',
			index: true,
			path: 'insuranceTypes',
			lazy: async () => ({ Component: (await import('@/modules/settings/pages/InsuranceTypes')).default }),
			loader: () => ({
				meta: {
					title: 'InsuranceTypes',
					disabled: true,
				} as RouteMeta,
			}),
		},
		{
			id: 'configuration',
			index: true,
			path: 'configuration',
			lazy: async () => ({ Component: (await import('@/modules/settings/pages/Configuration.tsx')).default }),
			loader: () => ({
				meta: {
					title: 'SMTP Settings',
					disabled: true,
				} as RouteMeta,
			}),
		},
		{
			id: 'emailTemplates',
			index: true,
			path: 'emailTemplates',
			lazy: async () => ({ Component: (await import('@/modules/settings/pages/EmailTemplates')).default }),
			loader: () => ({
				meta: {
					title: 'Email Templates',
					disabled: true,
				} as RouteMeta,
			}),
		},
		{
			id: 'smsTemplates',
			index: true,
			path: 'smsTemplates',
			lazy: async () => ({ Component: (await import('@/modules/settings/pages/SmsTemplates')).default }),
			loader: () => ({
				meta: {
					title: 'SMS Templates',
					disabled: true,
				} as RouteMeta,
			}),
		},
		{
			id: 'pbxSettings',
			index: true,
			path: 'pbx',
			lazy: async () => ({ Component: (await import('@/modules/settings/pages/Pbx')).default }),
			loader: () => ({
				meta: {
					title: 'PBX Settings',
					disabled: true,
				} as RouteMeta,
			}),
		},
	],
};

export default route;
