import ReactDOM from 'react-dom/client';
import '@/css/app.scss';
import '@/lib/firebase';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { LoadingOverlay } from '@mantine/core';
import { routes } from '@/pages/routes';

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
	// <StrictMode>
	<RouterProvider
		router={createBrowserRouter(routes)}
		fallbackElement={<LoadingOverlay visible={true} />}
	/>,
	// </StrictMode>,
);
