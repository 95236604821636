import { forwardRef, ReactNode } from 'react';
import { LoadingOverlay, Paper, PaperProps } from '@mantine/core';
import classNames from 'classnames';

type Props = {
	children: ReactNode;
	full?: boolean;
	fill?: boolean;
	className?: string;
	loading?: boolean;
	xs?: boolean;
	sm?: boolean;
	md?: boolean;
	lg?: boolean;
	xl?: boolean;
	xxl?: boolean;
	huge?: boolean;
	row?: boolean;
	screen?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | 'huge';
	noBottomMargin?: boolean;
} & Omit<PaperProps, 'children' | 'className'>;
export const TwContainer = forwardRef<HTMLDivElement, Props>(
	({ children, row, loading, noBottomMargin, huge, xl, md, xs, sm, lg, className, xxl, fill, full, screen, ...props }, ref) => {
		const s = screen || 'md';
		return (
			<Paper
				bg={'transparent'}
				ref={ref}
				className={classNames(className, 'tw-container tw-relative tw-mx-auto tw-flex tw-gap-2', {
					'tw-flex-col': !row,
					'tw-flex-row tw-flex-wrap': row,
					'tw-w-full !tw-m-0 tw-max-w-full': full,
					'tw-h-full': fill,
					'tw-max-w-1400px': !full || lg,
					[s + ':tw-max-w-540px']: xs,
					[s + ':tw-max-w-800px']: sm,
					[s + ':tw-max-w-1200px']: md,
					[s + ':tw-max-w-1800px']: xl,
					[s + ':tw-max-w-2400px']: xxl,
					[s + ':tw-max-w-3000px']: huge,
					'tw-mb-4': !noBottomMargin,
				})}
				{...props}>
				<LoadingOverlay visible={!!loading} />
				{children}
			</Paper>
		);
	},
);
