import { RouteObject } from 'react-router-dom';
import { RouteMeta } from '@/pages/routes';

const route: RouteObject = {
	path: 'reports',
	loader: () => ({
		meta: {
			title: 'Reports',
			htmlTitlePattern: '#title :: Reports',
		} as RouteMeta,
	}),
	lazy: async () => ({ Component: (await import('@/modules/reports/Layout')).default }),
	children: [
		{
			path: 'insurance/payments',
			index: true,
			id: 'reportInsurancePayments',
			loader: () => ({
				meta: {
					title: 'Insurance Payments',
					disabled: true,
				} as RouteMeta,
			}),
			lazy: async () => ({ Component: (await import('@/modules/reports/pages/Payments.tsx')).default }),
		},
	],
};
export default route;
