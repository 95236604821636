import { RouteObject } from 'react-router-dom';
import { RouteMeta } from '@/pages/routes';

const route: RouteObject = {
	path: 'patients',
	loader: () => ({
		meta: {
			htmlTitle: 'Patients',
			to: { id: 'patients' },
			title: 'Patients',
		} as RouteMeta,
	}),
	lazy: async () => ({ Component: (await import('@/modules/patient/Layout')).default }),
	children: [
		{
			path: '',
			index: true,
			id: 'patients',
			lazy: async () => ({ Component: (await import('@/modules/patient/pages/Patients')).default }),
		},
		{
			path: ':id',
			index: true,
			id: 'patient',
			lazy: async () => ({ Component: (await import('@/modules/patient/pages/Patient')).default }),
		},
		{
			path: 'tasks',
			index: true,
			id: 'patientTasks',
			loader: () => ({
				meta: {
					htmlTitle: 'Patients Tasks',
					title: 'Tasks',
					disabled: true,
				} as RouteMeta,
			}),
			lazy: async () => ({ Component: (await import('@/modules/patient/pages/Tasks')).default }),
		},
	],
};
export default route;
