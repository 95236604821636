import { RouteObject } from 'react-router-dom';
import { RouteMeta } from '@/pages/routes';

const route: RouteObject = {
	path: 'insurance',
	loader: () => ({
		meta: {
			title: 'Insurance',
			disabled: true,
			htmlTitlePattern: '#title :: Insurance',
		} as RouteMeta,
	}),
	lazy: async () => ({ Component: (await import('@/modules/insurance/Layout')).default }),
	children: [
		{
			path: 'enrollments/:status',
			index: true,
			id: 'enrollments',
			loader: (args) => ({
				meta: {
					title: 'Enrollments',
					pageTitle:
						args.params?.status === 'Pending'
							? 'Pending Insurance Enrollment'
							: args.params?.status === 'Enrolled'
								? 'Active Insurance Patients'
								: args.params?.status === 'Unenrolled'
									? 'Unenrolled Insurance Patients'
									: args.params?.status === 'Excised'
										? 'Cancelled Insurance Patients'
										: args.params?.status === 'All'
											? 'All Insurances'
											: '',
					disabled: true,
				} as RouteMeta,
			}),
			lazy: async () => ({ Component: (await import('@/modules/insurance/pages/List')).default }),
		},
		{
			path: ':insuranceID',
			index: true,
			id: 'insuranceProfile',
			loader: () => ({
				meta: {
					title: 'Insurance Profile',
					disabled: true,
				} as RouteMeta,
			}),
			lazy: async () => ({ Component: (await import('@/modules/insurance/pages/Profile')).default }),
		},
		{
			path: 'payments',
			index: true,
			id: 'insurancePayments',
			loader: () => ({
				meta: {
					title: 'Insurance Payments',
					disabled: true,
				} as RouteMeta,
			}),
			lazy: async () => ({ Component: (await import('@/modules/insurance/pages/Payments')).default }),
		},
	],
};
export default route;
