import { useRouteError } from 'react-router-dom';
import { Paper, Text, Title } from '@mantine/core';
import { IconAlertTriangle } from '@tabler/icons-react';
import { TwContainer } from '@/lib/components/TwContainer';

const NotFound = () => {
	const error = useRouteError() as Error;
	return (
		<TwContainer className={'tw-py-96  tw-mx-auto tw-text-center'}>
			<Paper p={36}>
				<IconAlertTriangle
					color={'red'}
					size={96}
				/>
				<Title
					order={1}
					className={'tw-pt-6 tw-text-4xl tw-font-semibold domaine'}>
					An error Occurred!
				</Title>
				<Title
					order={3}
					c={'red'}>
					{error.message}
				</Title>
				<Text className={'tw-text-left tw-text-red-400'}>{error.stack}</Text>
			</Paper>
		</TwContainer>
	);
};

export default NotFound;
